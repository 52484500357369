import { hardRedirect, trackEvent, isToggleEnabled } from '@paddl/utils-js';
import { Metadata, useResource, useSubmitData } from '@paddl/utils-react';
import { number } from 'prop-types';
import React, { useEffect } from 'react';
import {
  Avatar,
  AvatarGroup,
  defaultAvatar,
  InnerContentWrapper,
  MainContentWrapper,
  Colors,
  H1,
  H2,
  PageColumn,
  Column
} from '@paddl/storybook';
import Button from '@mui/material/Button';
import MuiAvatar from '@mui/material/Avatar';
import { profilesIntroText } from './profilesIntroText';

// eslint-disable-next-line react/prop-types
const LoadingWrapper = ({ children, isLoading }) => {
  if (isLoading) return null;

  return children;
};

export const GatewayContent = ({
  workspaceId
}) => {
  // TODO: redirect if my role in this workspace is not 'invited'

  // log page view
  useEffect(() => {
    if (window?.analytics) {
      window.analytics.page(
        'Workspace App',
        'Gateway',
        {
          workspaceId
        });
    }
  }, [workspaceId]);

  const { submit: submitJoinOrganisation, isLoading: isJoinOrganisationLoading } =  useSubmitData();

  const version = isToggleEnabled('experience-records') ? 'v3' : 'v2';
  const { data: directoryData } = useResource(`${process.env.AT_WORK_API}/${version}/workspace/${workspaceId}/directory`);
  const { data: workspaceData, isLoading: isWorkspaceLoading } =  useResource(`${process.env.AT_WORK_API}/v2/workspace/${workspaceId}/gateway`);
  const { workspaceName, logoUrl, organisationId } = workspaceData?.workspace ? workspaceData.workspace : { workspaceName: '', logoUrl: null };

  const joinWorkSpace = () => {
    trackEvent('Joined Workspace', { workspaceId });

    submitJoinOrganisation(`${process.env.AT_WORK_API}/v2/organisation/${organisationId}/join`).then(() => {
      hardRedirect('workspaceById', { id: workspaceId });
    });
  };

  return (
      <MainContentWrapper>
        <Metadata title="Join Workspace | Paddl" />
        <LoadingWrapper isLoading={isWorkspaceLoading}>
        <InnerContentWrapper>
          <PageColumn>
            <Column style={{ gap: '24px', textAlign: 'center', alignItems: 'center' }}>
            <Avatar
              imageAlt={`${workspaceName}`}
              imageUrl={logoUrl}
              name={workspaceName}
              variant="rounded"
              size="large"
              hasElevation
            />
            <H2 style={{ color: Colors.new_grey }}>{workspaceName}</H2>
            </Column>
            <Column style={{  textAlign: 'center', alignItems: 'center' }}>
            <H1>Grow your innovation, tech and leadership skills.</H1>
            <p>
              {workspaceName} is dedicated to supporting your personal growth,
              helping you contribute to their mission and building your skills for
              your own professional journey.
            </p>
            <p>
              Workplace Essentials delivers actionable upskilling Sessions focusing on
              innovation, tech savviness, leadership and communication to empower
              you in the ever-changing world of work.
            </p>
            <div style={{ margin: '36px 0' }}>
              <Button
                variant="contained"
                data-test-id="join-workspace"
                size="large"
                color="secondary"
                disabled={isJoinOrganisationLoading}
                onClick={() => joinWorkSpace()}
              >
                ✨ Join Workspace
              </Button>
            </div>
              <AvatarGroup max={5}>
                {directoryData?.workspaceDirectory?.map(({ avatarUrl, fullName, profileId }) => (
                  <MuiAvatar
                    className="profile-avatar"
                    src={avatarUrl || defaultAvatar}
                    alt={fullName}
                    data-testid={`avatar-${profileId}`}
                    key={profileId}
                  />
                ))}
              </AvatarGroup>
              <p data-testid="profiles-intro-text">
                {profilesIntroText(directoryData?.workspaceDirectory)}
              </p>
            </Column>
          </PageColumn>
        </InnerContentWrapper>
        </LoadingWrapper>
      </MainContentWrapper>
  );
};

GatewayContent.propTypes = {
  workspaceId: number.isRequired
};
