import React from 'react';
import { bool, number, func } from 'prop-types';
import { useResource, MembersDirectoryModal } from '@paddl/utils-react';
import { isToggleEnabled } from '@paddl/utils-js';

export const WorkspaceDirectoryModal = ({
  isModalOpen,
  workspaceId,
  onClose
}) => {
  const version = isToggleEnabled('experience-records') ? 'v3' : 'v2';
  const { data, isLoading } = useResource(`${process.env.AT_WORK_API}/${version}/workspace/${workspaceId}/directory`, !isModalOpen);
  const title = 'Workspace Members';
  const subtitle = 'Browse all members from this workspace.';

  return <MembersDirectoryModal
    isModalOpen={isModalOpen}
    onClose={onClose}
    members={data?.workspaceDirectory}
    isLoading={isLoading}
    title={title}
    subtitle={subtitle}
  />;
};

WorkspaceDirectoryModal.propTypes = {
  isModalOpen: bool,
  workspaceId: number.isRequired,
  onClose: func
};

WorkspaceDirectoryModal.defaultProps = {
  isModalOpen: false,
  onClose: () => {}
};
